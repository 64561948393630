<template>
  <div id="header" class="header">
    <div class="container">
      <a href="#" target="_self" class="nav-brand"><img src="@/assets/img/icon/导航栏logo.png" class="logo"/></a>
      <div class="nav-collapse">
        <ul class="menu ul-horizontal">
          <li class="item" v-bind:class="{ active: activeIndex === 1}" @click="selected(1)">首页</li>
          <li class="item" v-bind:class="{ active: activeIndex === 2}" @click="selected(2)">达人搜索</li>
          <li class="item" v-bind:class="{ active: activeIndex === 3}" @click="selected(3)">关于我们</li>
          <li class="item" v-bind:class="{ active: activeIndex === 4}" @click="selected(4)">营销学院</li>
        </ul>
      </div>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
  <div class="footer" id="footer" 
      :style="{
        'background-image': `url(${require('@/assets/img/background/ch/背景4.png')})`,
      }"
  >
    <div class="row">
      <div class="row-item">
        <div class="logo">
          <img class="logo-icon" :src="require('@/assets/img/icon/导航栏logo.png')"/>
          <p>梨科技为中国出海品牌<br/>提供海内外营销全案服务！</p>
        </div>
      </div>
      <div class="row-item">
        <h3 class="label">联系我们</h3>
        <div class="contact">
          <p><span class="icon"><img :src="require('@/assets/img/icon/电话.png')"/></span><span class="text">电话：18911391307</span></p>
          <p><span class="icon"><img :src="require('@/assets/img/icon/邮箱.png')"/></span><span class="text">xukuiliang@peartechs.com</span></p>
          <p><span class="icon"></span><span>shaochunyue@peartechs.com</span></p>
          <p><span class="icon"><img :src="require('@/assets/img/icon/地址.png')"/></span><span class="text">北京市昌平区回龙观龙域北街10号院1号楼5层</span></p>
        </div>
      </div>
      <div class="row-item">
        <div class="media">
          <p><span class="icon"><img :src="require('@/assets/img/icon/新浪微博.png')"/></span><a href="javascript:void(0)" @click="open_url('https://weibo.com/u/7727171108')" class="text">梨科技品牌出海</a></p>
          <p><span class="icon"><img :src="require('@/assets/img/icon/instagram.png')"/></span><a href="javascript:void(0)"  @click="open_url('https://www.instagram.com/ptech.mobi/')" class="text">ptech.mobi</a></p>
          <p><span class="icon"><img :src="require('@/assets/img/icon/facebook.png')"/></span><a href="javascript:void(0)" @click="open_url('https://www.facebook.com/PearTechnology-100831549123998#_=_')" class="text">Pear He</a></p>
          <p><span class="icon"><img :src="require('@/assets/img/icon/twitter.png')"/></span><a href="javascript:void(0)" @click="open_url('https://twitter.com/makeismike666')" class="text">BJPeartech@gamail.com</a></p>
        </div>
      </div>
      <div class="row-item">
        <h3 class="label">关注我们</h3>
        <div class="focus">
          <img :src="require('@/assets/img/icon/微信.png')"/>
          <img :src="require('@/assets/img/icon/公众号.png')"/>
        </div>
      </div>
    </div>
    <div class="icp"><a href="https://beian.miit.gov.cn/">京ICP备2021025842号-2</a></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, ref, WebH} from 'vue'
import {useRouter} from 'vue-router'

export default {
  name: 'Layout',
  components: {
  },
  setup() {
    let activeIndex = ref(1)
    let router = useRouter()
    let selected = (index) => {
      activeIndex.value = index
      if (index === 2) {
          window.open('https://www.pilotkol.com/search')
      }
      else if (index == 3) {
        window.open('https://www.peartechs.com/before/about-us.html')
      }
      else if (index == 4) {
        window.open('https://www.pilotkol.com/marketing/?lang=en')
      }
    }
    let open_url = (url) => {
      window.open(url)
    }
    return {
      activeIndex,
      selected,
      open_url
    }
  }

}
</script>

<style lang="scss" scoped>
  .header {
    width: 100%;
    height: 88px;
    background: #0d0719;
    position: relative;
    .container {
      padding: 0 360px;
      height: 100%;
      width: 1200px;
      position: absolute;
      font-size: 22px;
      color: #a7bcff;
      .nav-brand {
        width: 170px;
        height: 88px;
        position: absolute;
        .logo {
          width: 170px;
          height: 100%;
        }
      }
      .nav-collapse {
        text-align: center;
        padding-left: 258px;
        // background: #fff;
        position: absolute;
        line-height: 88px;
        width: 656px;
        height: 100%;
        .menu .item {
          width: 164px;
          &:hover {
            color: #fff;
            background: #226DDD;
          }
        }
        .menu .active {
          font-size: 24px;
          font-weight: 50 0;
          color: #fff;
        }

      }
    }
  }
  .footer {
    background-size: 100% 100%;
    // height: 360px;
    background-color: rgb(13, 8, 28);
    .row {
      font-size: 16px;
      display: flex;
      height: 250px;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      width: 1400px;
      padding-top: 100px;
      color: #ffffff;
      // padding-bottom: 50px;
      .row-item {
        position: relative;
        padding: 0 20px;
        .label {
          font-size: 20px;
          color: #a7bcff;
          position: absolute;
          top: -40px;
        }
        .logo-icon {
          // width: 170px;
          margin-left: -30px;
          height: 100px;
        }
      }
      .contact {
        p {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          .icon {
            width: 34px;
            margin-right: 16px;
            text-align: center;
            img {
              width: 100%;
            }
          }
        }
        p:nth-child(4) {
            padding-top: 30px;
          }
      }
      .media {
        p {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          .icon {
            width: 34px;
            margin-right: 16px;
            text-align: center;
            img {
              width: 100%;
            }
          }
        }
      }
      a {
        text-decoration:none;
        color: #ffffff;
        &:hover {
          color: #a7bcff;
        }
      }
      .focus {
        img {
          width: 161px;
          height: 161px;
        }
        img:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
  }
  .icp {
    text-align: center;
    height: 64px;
    line-height: 64px;
    font-size: 18px;
    // background-color: #170231;
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
          color: #007bff;
          text-decoration: underline;
      }
    }
  }
</style>